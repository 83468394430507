import { ThemeColorsT } from "get-life-storybook-ts/lib/constants/GetlifeStyles";
import { UserStatusT } from "../../../context/GetLifeContext.context";
import HttpGlobal from "../Global/HttpGlobal.class";
import {
  DataI,
  DataPublicI,
  FacileBasePriceI,
  FacileLeadI,
  PostFacileLeadI,
} from "./Interfaces/DataInterface.response";
import {
  InsuranceCalculateDataI,
  InsuranceCalculateI,
} from "./Interfaces/InsuranceCalculate";
import {
  OrganicRequestI,
  OrganicResponseI,
} from "./Interfaces/OrganicInterface";
import { ProductI } from "./Interfaces/ProductInterface.response";

export default class HttpLeadRepository extends HttpGlobal {
  private readonly API_URL:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api`;

  private readonly API_URL_LEAD:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/lead`;

  private readonly API_URL_LEAD_CHECKOUT:
    | string
    | undefined = `${this.API_URL_LEAD}/checkout`;

  private readonly API_URL_LEAD_BENEFICIARIES:
    | string
    | undefined = `${this.API_URL_LEAD_CHECKOUT}/beneficiaries`;

  private readonly API_URL_DOCUMENT:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/documents`;

  private readonly API_URL_EVENTS:
    | string
    | undefined = `${process.env.REACT_APP_EVENTS_API}public/front/event`;

  getBeneficiaries = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD_BENEFICIARIES}/${this.leadId}`
    );
    return response;
  };

  getBeneficiariesTypes = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD_BENEFICIARIES}/types/${this.leadId}`
    );
    return response;
  };

  getCheckoutData = async (): Promise<DataI> => {
    const response: DataI = await this.generalGet(
      `${this.API_URL_LEAD_CHECKOUT}/data/get/${this.leadId}`
    );
    return response;
  };

  getCheckoutProduct = async (): Promise<ProductI> => {
    const response: ProductI = await this.generalGet(
      `${this.API_URL_LEAD_CHECKOUT}/product/get/${this.leadId}`
    );
    return response;
  };

  getCheckoutNote = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD_CHECKOUT}/note/get/${this.leadId}`
    );
    return response;
  };

  getClientStatus = async (): Promise<{
    status: UserStatusT;
    themeColor: ThemeColorsT | "";
    logo: string | null;
  }> => {
    const response: {
      status: UserStatusT;
      themeColor: ThemeColorsT | "";
      logo: string | null;
    } = await this.generalGet(`${this.API_URL_LEAD}/status/get/${this.leadId}`);
    return response;
  };

  getInfoLead = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/info/${this.leadId}`
    );
    return response;
  };

  getConfirmationInfo = async () => {
    const response = await this.generalGet(
      `${this.API_URL}/confirmation/info/${this.leadId}`
    );
    return response;
  };

  getInfoLeadV2 = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/info/v2/${this.leadId}`
    );
    return response;
  };

  getProductQuestion = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/get/product/${this.leadId}`
    );
    return response;
  };

  getProfessions = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/get-scor-professions`
    );
    return response;
  };

  getQuestion = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/get/${this.leadId}`
    );
    return response;
  };

  getQuestionField = async (field: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/${field}/${this.leadId}`
    );
    return response;
  };

  getQuestionNomenclator = async (category: string) => {
    if (category) {
      const response = await this.generalGet(
        `${this.API_URL_LEAD}/question/nomenclator/get/${category}`
      );
      return response;
    }
  };

  getQuoteData = async (leadId: string): Promise<DataPublicI> => {
    const response = await this.generalGet(
      `${this.API_URL}/lead/quote/${leadId}`
    );
    return response;
  };

  getRegionalData = async (zipCode: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/regionalData/get/${zipCode}`
    );
    return response;
  };

  getCityFromZipCode = async (zipCode: string) => {
    const response = await this.generalGet(
      `${this.API_URL}/it/cities/${zipCode}/`
    );
    return response;
  };

  getReset = async (email: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/reset/${email}`
    );
    return response;
  };

  getToken = async (leadId: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/token/${leadId}`
    );
    return response;
  };

  callMe = async (phone: string | number, leadId?: string) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/callme`,
      dataRequest: { phone, leadId: leadId || this.leadId },
    });

    return response;
  };

  leadAggregator = async ({ phone, date }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/aggregator/callmeback`,
      dataRequest: { phone, date },
    });

    return response;
  };

  login = async (userData: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/login`,
      dataRequest: { ...userData },
      header: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  loginOtp = async (email: string) => {
    const response = await this.generalGet(`${this.API_URL_LEAD}/otp/${email}`);

    return response;
  };

  GoogleSSO = async (code: string) => {
    const response = await this.generalGet(
      `${this.API_URL}/public/lead/sso/login?code=${code}`
    );

    return response;
  };

  editQuestion = async (questionId: number | string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/edit/${this.leadId}/${questionId}`
    );

    return response;
  };

  editProductQuestion = async (questionId: number | string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/question/edit/product/${this.leadId}/${questionId}`
    );

    return response;
  };

  partnerRastreator = async ({ token, data }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/partner/rastreator/${token}`,
      dataRequest: data,
    });

    return response;
  };

  getPartnerFacile = async (token: string): Promise<FacileLeadI> => {
    const response = await this.generalGet(
      `${this.API_URL}/public/facile/token/${token}`
    );

    return response;
  };

  getPartnerFacilePrice = async ({
    dateOfBirth,
    gender,
    capital,
    nicotine,
  }: {
    dateOfBirth: string;
    gender: string;
    capital: number;
    nicotine: boolean;
  }): Promise<FacileBasePriceI> => {
    const response = await this.generalGet(
      `${this.API_URL}/public/facile/basic/prices/${dateOfBirth}/${capital}/${nicotine}/${gender}`
    );

    return response;
  };

  postOrganicEmail = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/organic/email`,
      dataRequest: {},
    });

    return response;
  };

  postQuestionField = async ({ field, value }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/question/field/${this.leadId}`,
      dataRequest: { questionField: field, value: String(value) },
    });

    return response;
  };

  postFacileOrganic = async (data: PostFacileLeadI) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/organic/facile`,
      dataRequest: data,
    });

    return response;
  };

  resetQuote = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/reset_quote`,
      dataRequest: {},
    });

    return response;
  };

  saveQuestion = async ({ answer, questionId }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/question/save`,
      dataRequest: {
        leadId: this.leadId,
        answer: answer,
        questionId: questionId,
      },
    });

    return response;
  };

  saveData = async (data: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_CHECKOUT}/data/save/${this.leadId}`,
      dataRequest: {
        ...data,
      },
    });

    return response;
  };

  saveTerzoReferente = async (data: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/contact-person/add`,
      dataRequest: {
        ...data,
      },
    });

    return response;
  };

  saveBeneficiaries = async ({ types, beneficiaries }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_BENEFICIARIES}/save`,
      dataRequest: {
        leadId: this.leadId,
        type: types,
        beneficiaries: beneficiaries,
      },
    });

    return response;
  };

  saveBeneficiariesTypes = async (beneficiariesTypes: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_BENEFICIARIES}/save/types`,
      dataRequest: {
        leadId: this.leadId,
        beneficiariesTypes: beneficiariesTypes,
      },
    });

    return response;
  };

  track = async (data: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/track/v2`,
      dataRequest: data,
    });

    return response;
  };

  trackCompare = async (data: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/track/v2/compare`,
      dataRequest: data,
    });

    return response;
  };

  saveEvent = async (data: any) => {
    const isLocalhost = window.location.hostname === "localhost";
    if (isLocalhost) return;
    const response = await this.generalPost({
      url: `${this.API_URL_EVENTS}`,
      dataRequest: data,
    });

    return response;
  };

  updateBeneficiariesCapital = async (beneficiaries: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_BENEFICIARIES}/update/capital`,
      dataRequest: { leadId: this.leadId, beneficiaries: beneficiaries },
    });

    return response;
  };

  updateBeneficiariesNotify = async (data: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_BENEFICIARIES}/update/email`,
      dataRequest: data,
    });

    return response;
  };

  updateEffectDate = async (effectDate: string) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_CHECKOUT}/lead/updateEffectDate/${this.leadId}/${effectDate}`,
      dataRequest: {},
    });

    return response;
  };

  updatePeriod = async (purchasePeriod: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/period/update/${this.leadId}`,
      dataRequest: {
        period: purchasePeriod,
      },
    });

    return response;
  };

  updateProductUpsell = async ({ capital, ipa, offer }: any) => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD_CHECKOUT}/product/update`,
      dataRequest: {
        capital: parseInt(capital),
        product: ipa,
        offer,
        leadId: this.leadId,
      },
    });

    return response;
  };

  getCapitals = async (birthDate: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/capital/tarifier/boundaries/${birthDate}`
    );

    return response;
  };

  getCapitalsExtended = async (birthDate: string) => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/capital/tarifier/extendedBoundaries/${birthDate}`
    );

    return response;
  };

  postLeadOrganic = async (
    data: OrganicRequestI
  ): Promise<OrganicResponseI> => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/organic`,
      dataRequest: data,
    });

    return response;
  };

  getTarrif = async ({
    capital,
    insuranceType,
  }: InsuranceCalculateI): Promise<InsuranceCalculateDataI> => {
    const response = await this.generalGet(
      `${this.API_URL}/insurance/calculate/${this.leadId}/${capital}/${insuranceType}`
    );

    return response;
  };

  assingABTest = async () => {
    const response = await this.generalPost({
      url: `${this.API_URL_LEAD}/assign-is-ab-test`,
      dataRequest: { leadId: this.leadId },
    });

    return response;
  };

  getDocuments = async () => {
    const response = await this.generalGet(
      `${this.API_URL_DOCUMENT}/get/${this.leadId}`
    );

    return response;
  };

  getApprovedPolices = async () => {
    const response = await this.generalGet(
      `${this.API_URL}/info/policies/approved`
    );
    return response;
  };

  getPaymentData = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/payment/${this.leadId}`
    );
    return response;
  };

  getUpsellData = async () => {
    const response = await this.generalGet(
      `${this.API_URL_LEAD}/upsell/${this.leadId}`
    );
    return response;
  };

  getPricingCapitals = async () => {
    const response = await this.generalGet(
      `${this.API_URL}/pricing/capitals/${this.leadId}`
    );
    return response;
  };
}
