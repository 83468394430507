import {
  ButtonDS,
  Chip,
  GlobalIcon,
  ModalDS,
  TabsDS,
} from "get-life-storybook-ts";
import { useContext, useEffect, useRef, useState } from "react";
import { I18nContext } from "../../../../../context/i18n.context";
import numberFormat from "../../../../../utils/Utils";
import { DataI } from "../../../../../api/request/Lead/Interfaces/DataInterface.response";
import { ProductI } from "../../../../../api/request/Lead/Interfaces/ProductInterface.response";
import { GlobalTypeFormPolicy } from "../../../../../utils/InternationlChanges";
import { DOMAIN } from "../../../../../constants/Global";
import {
  GetlifeContext,
  LanguageT,
} from "../../../../../context/GetLifeContext.context";
import apiGetDownloadableFiles from "./apiGetDownloadableFiles";
import ConditionsModalAB from "../../../../../components/ConditionsModal/ConditionsModalAB";
import { DateTime } from "luxon";
import { DataCardI } from "../../../../../api/request/Payment/Interfaces/PaymentMethodI.response";
import HttpPaymentRepository from "../../../../../api/request/Payment/Payment.service";
import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { SetupIntentResult, StripeElementChangeEvent } from "@stripe/stripe-js";
import CardSetupForm from "../../PaymentGateway/components/CardSetupForm/CardSetupForm.component";
import HttpLeadRepository from "../../../../../api/request/Lead/Lead.service";

type PaymentMethodT = "card" | "sepa_debit";

type ConfirmStripeIntentT = "confirmCardSetup" | "confirmSepaDebitSetup";

interface NewPolicyI {
  userData?: DataI;
  product?: ProductI;
}

type PaymentMethodSelectedT = {
  [k in PaymentMethodT]: {
    stripeFunction: ConfirmStripeIntentT;
    element: any;
    billing_details: string | any;
  };
};

enum PaymentMethod {
  card = "CREDIT_CARD",
  sepa_debit = "SEPA",
}

interface StripeIntentStatusI {
  complete: StripeElementChangeEvent["complete"];
  error: StripeElementChangeEvent["error"];
}

const NewPolicy = ({ userData, product }: NewPolicyI) => {
  const stripe = useStripe();
  const elements = useElements();
  const [downloadables, setDownloadables] = useState([]);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { leadId, token, handleCallMe, setNotification } =
    useContext(GetlifeContext);
  const lead = userData?.lead;
  const [beneficiaryList, setBeneficiaryList] = useState<any>({});
  const [renewalModal, setRenewalModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [methodModal, setMethodModal] = useState<boolean>(false);
  const [dataCard, setDataCard] = useState<DataCardI | null>(null);
  const [setupIntent, setSetupIntent] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodT>("card");
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [stripeInputStatus, setStripeInputStatus] =
    useState<StripeIntentStatusI>({
      complete: false,
      error: undefined,
    });

  const nodeConditionsModal: any = useRef(null);

  const paymentRepository = new HttpPaymentRepository(leadId, token);
  const leadRepository = new HttpLeadRepository(leadId, token);

  const modifyData = () =>
    window.open(GlobalTypeFormPolicy[DOMAIN as LanguageT], "_blank");

  const handleConditions = () =>
    nodeConditionsModal.current?.handleShowPortal();

  const toggleBeneficiaryList = (idx: number) => {
    const updatedList = { ...beneficiaryList };
    if (updatedList[idx] !== undefined) {
      updatedList[idx] = !updatedList[idx];
    } else {
      updatedList[idx] = true;
    }
    setBeneficiaryList(updatedList);
  };

  const toggleRenewalModal = () => setRenewalModal(!renewalModal);
  const toggleCancelModal = () => setCancelModal(!cancelModal);
  const toggleMethodModal = () => {
    setPaymentMethod("card");
    setMethodModal(!methodModal);
  };

  const handleClickRedirection = () => {
    window.open(
      "https://share-eu1.hsforms.com/1gcPqSm97R9GvFPyi_675Ewft7mp",
      "_blank"
    );
  };

  const paymentMethodSelected: PaymentMethodSelectedT = {
    card: {
      stripeFunction: "confirmCardSetup",
      element: CardNumberElement,
      billing_details: lead?.name + " " + lead?.lastName,
    },

    sepa_debit: {
      stripeFunction: "confirmSepaDebitSetup",
      element: IbanElement,
      billing_details: {
        name: lead?.name + " " + lead?.lastName,
        email: lead?.email,
      },
    },
  };

  const handleSubmitMethod = async () => {
    if (!stripe || !elements) return;
    setUpdateLoading(true);
    const stripeSetUpElement = paymentMethodSelected[paymentMethod!];
    try {
      const result: SetupIntentResult = await stripe[
        stripeSetUpElement.stripeFunction
      ](setupIntent?.clientSecret, {
        payment_method: {
          [paymentMethod]: elements.getElement(stripeSetUpElement.element),
          billing_details: stripeSetUpElement.billing_details,
        } as any,
      });
      if (result.error) {
        setNotification({
          message: translate("dashboard.policy.alert.paymentMethod.error"),
          type: "error",
          time: 5,
        });
      } else {
        await fetchUpdatePaymentMethod(result);
        await fetchSetupIntent();
      }
    } catch (error) {
      setUpdateLoading(false);
      setNotification({
        message: translate("dashboard.policy.alert.paymentMethod.error"),
        type: "error",
        time: 5,
      });
    }
    setUpdateLoading(false);
    setMethodModal(false);
  };

  const fetchUpdatePaymentMethod = async (result: SetupIntentResult) => {
    try {
      await paymentRepository.updatePaymentMethod({
        ulid: leadId,
        paymentMethodId: result.setupIntent?.payment_method!,
        typePaymentMethod: PaymentMethod[paymentMethod],
      });
      await fetchGetDataCard();
      setNotification({
        message: translate("dashboard.policy.alert.paymentMethod.success"),
        type: "success",
        time: 5,
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchGetDataCard = async (): Promise<void> => {
    try {
      const response: DataCardI = await paymentRepository.getDataCard();
      setDataCard(response);
    } catch (error) {
      setDataCard(null);
    }
  };

  const handleStripeStatus = (
    index: any,
    value: any,
    index2: any,
    value2: any
  ) => {
    const prevValue = stripeInputStatus;

    setStripeInputStatus({
      ...prevValue,
      [index]: value,
      [index2]: value2,
    });
  };

  useEffect(() => {
    (async () => {
      const files = await apiGetDownloadableFiles(
        leadId,
        translate,
        userData?.coverage
      );
      const contract = await leadRepository.getDocuments();
      if (contract.contractUrl) {
        files.push({
          href: contract.contractUrl,
          label: translate("dashboard.policy.contract.downloadBtn"),
        });
      }
      setDownloadables(files as any);
    })();
  }, [userData]);

  const fetchSetupIntent = async () => {
    try {
      const response = await paymentRepository.setup();
      setSetupIntent(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchGetDataCard();
      await fetchSetupIntent();
    })();
  }, []);

  const couponInfo =
    product && product.promotion
      ? {
          conditions: product?.promotion?.conditions,
          exceptions: product?.promotion?.exceptions,
          code: product?.promotion?.promotionalCode,
        }
      : undefined;

  const coverages = {
    basic: translate("newDashboard.coverage.basic"),
    premium: translate("newDashboard.coverage.premium"),
    eg: translate("newDashboard.coverage.eg"),
  };

  const frequency =
    userData?.feeCollection && userData?.feeCollection.length > 0
      ? userData?.feeCollection[0].frequency
      : "MONTHLY";

  const signatureDate = product?.signatureDate
    ? DateTime.fromFormat(product.signatureDate, "dd/MM/yyyy").toFormat(
        "dd-MM-yyyy"
      )
    : undefined;

  const effectDate = product?.effectDate
    ? DateTime.fromISO(product?.effectDate).toFormat("dd-MM-yyyy")
    : undefined;

  const renewalDate = product?.renewalDate
    ? DateTime.fromFormat(product.renewalDate, "dd/MM/yyyy").toFormat(
        "dd-MM-yyyy"
      )
    : undefined;

  const hasBeneficiaries = lead?.beneficiaries;
  const beneficiariesLabel: { [key: string]: string } = {
    "Herederos Legales": translate("newDashboard.legalHeirs"),
    "Orden de Prelación": translate("newDashboard.prelationOrder"),
    other: translate("newDashboard.freeDesignation"),
    "Legal heirs": translate("newDashboard.legalHeirs"),
    "My children": translate("newDashboard.myChildren"),
    "Testament heirs": translate("newDashboard.testamentHeirs"),
    "My spouse": translate("newDashboard.mySpouse"),
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: process.env.REACT_APP_DOMAIN_LOCALE,
  };

  return (
    <>
      <div className="grid md:grid-cols-2 gap-[32px]">
        <div className="flex flex-col gap-[16px]">
          <div className="dashboard-card flex flex-col">
            <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
              <GlobalIcon iconName="CashIcon" size="XS" color="currentColor" />
              <span className="flex-1 BodyL font-bold">
                {translate("newDashboard.policy.capitalAndGuarantees")}
              </span>
            </div>
            <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col gap-[4px]">
              <span className="BodyM text-[#7B7B7B] font-medium">
                {translate("newDashboard.assuredCapital")}
              </span>
              {lead?.capital ? (
                <Chip
                  text={numberFormat(lead.capital as number, "capital")}
                  icon={null}
                  size="big"
                />
              ) : null}
            </div>
            <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col gap-[4px]">
              <span className="BodyM text-[#7B7B7B] font-medium">
                {translate("newDashboard.guarantee")}
              </span>
              {product?.coverage ? (
                <Chip
                  text={coverages[product.coverage]}
                  icon={null}
                  size="big"
                  className="[&>span]:!whitespace-break-spaces"
                />
              ) : null}
            </div>
            <ButtonDS
              label={translate("dashboard.policy.editBtn")}
              buttonType="tertiary"
              ghost={true}
              onClick={modifyData}
              size="32"
              className="w-fit ml-auto mt-[16px]"
            />
            {downloadables.length > 0 ? (
              <div className="flex flex-col gap-[4px] mt-[16px]">
                {downloadables.map((d, idx) => {
                  const { label, href } = d;
                  return (
                    <a
                      key={`${label}_${idx}`}
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      className="text-[var(--theme-primary)] flex flex-row gap-[8px] items-center BodyS font-bold underline py-[8px]"
                    >
                      <GlobalIcon
                        iconName="FileDownload"
                        size="XXS"
                        color="currentColor"
                      />
                      <span>{label}</span>
                    </a>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="dashboard-card flex flex-col">
            <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
              <GlobalIcon
                iconName="Calendar2Icon"
                size="XS"
                color="currentColor"
              />
              <span className="flex-1 BodyL font-bold">
                {translate("newDashboard.policy.payments")}
              </span>
            </div>
            <div className="py-[16px] flex flex-row flex-wrap gap-[8px] border-b-[var(--light-gray)] border-b ">
              <p className="flex-1">
                <span className="BodyM text-[#7B7B7B] font-medium">
                  {translate(
                    frequency === "MONTHLY"
                      ? "newDashboard.monthlyFee"
                      : "newDashboard.yearlyFee"
                  )}
                </span>
                <br />
                <span className="BodyL text-[var(--dark-gray)] font-medium">
                  {userData?.price
                    ? numberFormat(
                        frequency === "MONTHLY"
                          ? userData.price
                          : product!.finalYearlyPrice,
                        "price"
                      )
                    : null}
                </span>
              </p>
              {product?.promotion ? (
                <ButtonDS
                  label={translate("newDashboard.promotion")}
                  buttonType="tertiary"
                  ghost={true}
                  size="32"
                  leftIcon="Discount2Icon"
                  onClick={handleConditions}
                />
              ) : null}
            </div>
            {dataCard ? (
              <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-row gap-[8px]">
                <div className="flex flex-col">
                  <span className="text-[#7B7B7B] BodyM font-medium">
                    {translate("newDashboard.paymentMethod")}
                  </span>
                  <span className="text-[var(--dark-gray)] BodyL font-medium">
                    {`${translate(`newDashboard.${dataCard.type}`)} **** ${
                      dataCard.lastFourDigits
                    }`}
                  </span>
                </div>
                <ButtonDS
                  label={translate("dashboard.policy.editBtn")}
                  buttonType="tertiary"
                  ghost={true}
                  size="32"
                  onClick={() => {
                    toggleMethodModal();
                  }}
                  className="ml-auto"
                />
              </div>
            ) : null}
            {signatureDate ? (
              <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
                <span className="text-[#7B7B7B] BodyM font-medium">
                  {translate("newDashboard.policy.signatureDate")}
                </span>
                <span className="text-[var(--dark-gray)] BodyL font-medium">
                  {signatureDate}
                </span>
              </div>
            ) : null}
            {effectDate ? (
              <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col">
                <span className="text-[#7B7B7B] BodyM font-medium">
                  {translate("newDashboard.policy.effectDate")}
                </span>
                <span className="text-[var(--dark-gray)] BodyL font-medium">
                  {effectDate}
                </span>
              </div>
            ) : null}
            {renewalDate ? (
              <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col gap-[16px]">
                <div className="flex flex-row">
                  <div className="flex flex-col flex-1">
                    <span className="text-[#7B7B7B] BodyM font-medium">
                      {translate("newDashboard.policy.renewalDate")}
                    </span>
                    <span className="text-[var(--dark-gray)] BodyL font-medium">
                      {renewalDate}
                    </span>
                  </div>
                  <ButtonDS
                    label={translate("newDashboard.moreDetails")}
                    buttonType="tertiary"
                    size="32"
                    ghost={true}
                    onClick={toggleRenewalModal}
                  />
                </div>
                <p className="BodyS font-medium text-[#7B7B7B]">
                  {translate("newDashboard.policy.policyRenewal")}
                </p>
              </div>
            ) : null}
            <ButtonDS
              label={translate("newDashboard.policy.cancelPolicy")}
              buttonType="secondary"
              size="32"
              ghost={true}
              className="w-fit ml-auto text-[#7B7B7B] underline mt-[8px]"
              onClick={toggleCancelModal}
            />
          </div>
        </div>
        <div className="dashboard-card flex flex-col">
          <div className="text-[var(--dark-gray)] flex flex-row items-center gap-[8px] flex-wrap pb-[16px] border-b-[var(--light-gray)] border-b">
            <GlobalIcon iconName="HeartIcon" size="XS" color="currentColor" />
            <span className="flex-1 BodyL font-bold">
              {translate("newDashboard.policy.beneficiaries")}
            </span>
          </div>
          <div className="py-[16px] border-b-[var(--light-gray)] border-b">
            <div className="flex flex-row">
              <div className="flex flex-col gap-[4px] flex-1">
                <span className="BodyM text-[#7B7B7B] font-medium">
                  {translate("newDashboard.beneficiaries")}
                </span>
                {hasBeneficiaries ? (
                  <Chip
                    text={beneficiariesLabel[lead.beneficiaries]}
                    icon={null}
                    size="big"
                    className="[&>span]:!whitespace-normal [&>span]:!break-normal rounded-[25px]"
                  />
                ) : null}
              </div>
            </div>
          </div>
          {product?.beneficiariesList && product?.beneficiariesList.length > 0
            ? product?.beneficiariesList.map((b, idx) => {
                const { Capital, Nombre, Identificador } = b;
                const isOpen = beneficiaryList[idx] === true;
                return (
                  <div className="py-[16px] border-b-[var(--light-gray)] border-b flex flex-col gap-[16px]">
                    <div className="flex flex-row gap-[8px]">
                      <div className="flex flex-col flex-1">
                        <span className="text-[#7B7B7B] BodyM font-medium">
                          {translate("newDashboard.menu.profile.name")}
                        </span>
                        <span className="text-[var(--dark-gray)] BodyL font-medium">
                          {Nombre}
                        </span>
                      </div>
                      <button onClick={() => toggleBeneficiaryList(idx)}>
                        <span
                          className="block transition-all"
                          style={{
                            transform: `rotate(${isOpen ? 180 : 0}deg)`,
                          }}
                        >
                          <GlobalIcon
                            iconName="ChevronDown"
                            size="XS"
                            color="var(--dark-gray)"
                          />
                        </span>
                      </button>
                    </div>
                    <div
                      className="pl-[24px] flex flex-col gap-[16px] transition-all overflow-hidden"
                      style={{ maxHeight: isOpen ? 600 : 0 }}
                    >
                      <div className="flex flex-col">
                        <span className="BodyS text-[#7B7B7B] font-medium">
                          {translate("newDashboard.policy.identifier")}
                        </span>
                        <span className="BodyM text-[var(--dark-gray)] font-medium">
                          {Identificador}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="BodyS text-[#7B7B7B] font-medium">
                          {translate("newDashboard.policy.capitalAssigned")}
                        </span>
                        <span className="BodyM text-[var(--dark-gray)] font-medium">
                          {numberFormat(Capital, "capital")}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          <ButtonDS
            label={translate("newDashboard.menu.profile.modifyData")}
            buttonType="tertiary"
            ghost={true}
            className="w-fit ml-auto mt-[8px]"
            size="32"
            onClick={modifyData}
          />
        </div>
      </div>
      {product?.promotion && (
        <ConditionsModalAB
          couponInfo={couponInfo}
          nodeFakeDoorModal={nodeConditionsModal}
          promotion={product.promotion}
          collection={userData!.feeCollection}
          period={(product.period?.toLowerCase() || "monthly") as any}
          handleSubmit={() => nodeConditionsModal.current?.handleShowPortal()}
        />
      )}
      <ModalDS
        onClose={() => setRenewalModal(false)}
        open={renewalModal}
        title={translate("newDashboard.policy.renewPolicyTitle")}
        content={
          <div>
            <GlobalIcon iconName="ContractUpdateIcon" size="L" />
            <p
              className="mt-[16px] text-[var(--dark-gray)] BodyL"
              dangerouslySetInnerHTML={{
                __html: translate(
                  "newDashboard.policy.renewPolicyDescription"
                ).replace("{renewalDate}", renewalDate!),
              }}
            />
          </div>
        }
      />
      <ModalDS
        onClose={() => setCancelModal(false)}
        open={cancelModal}
        title={translate("newDashboard.policy.cancelModalTitle")}
        content={
          <div>
            <GlobalIcon iconName="ContractFailedIcon" size="L" />
            <p
              className="mt-[16px] text-[var(--dark-gray)] BodyL"
              dangerouslySetInnerHTML={{
                __html: translate("newDashboard.policy.cancelModalDescription"),
              }}
            />
          </div>
        }
        buttons={
          <div>
            <ButtonDS
              label={translate("newDashboard.policy.cancelButton")}
              className="min-w-[50%] ml-auto"
              onClick={() => {
                setCancelModal(false);
                if (DOMAIN === "fr") {
                  handleClickRedirection();
                } else {
                  handleCallMe();
                }
              }}
            />
          </div>
        }
      />
      <ModalDS
        onClose={() => setMethodModal(false)}
        open={methodModal}
        title={translate("newDashboard.paymentMethod.modal.title")}
        icon="CreaditCard"
        content={
          <TabsDS
            tabs={[
              {
                label: translate("newDashboard.paymentMethod.modal.card"),
                icon: "CreaditCard",
                panel: (
                  <div className="pt-[40px]">
                    <CardSetupForm
                      handleStripeStatus={handleStripeStatus}
                      stripeInputStatus={stripeInputStatus}
                      translate={translate}
                    />
                  </div>
                ),
                callback: () => setPaymentMethod("card"),
              },
              {
                label: translate("newDashboard.paymentMethod.modal.sepa"),
                icon: "BuildingBankIcon",
                panel: (
                  <div className="pt-[40px]">
                    <div className="mb-[8px] BodyL text-[#424242]">
                      {translate("newDashboard.paymentMethod.modal.iban")}
                    </div>
                    <IbanElement
                      options={IBAN_ELEMENT_OPTIONS}
                      className="py-[12px] px-[16px] rounded-xl border border-[#ECECEC] BodyM"
                    />
                  </div>
                ),
                callback: () => setPaymentMethod("sepa_debit"),
              },
            ]}
          />
        }
        buttons={
          <div>
            <ButtonDS
              label={translate("dashboard.policy.editBtn")}
              className="min-w-[50%] ml-auto"
              onClick={() => {
                handleSubmitMethod();
              }}
              leftIcon="CheckedIcon"
              disabled={updateLoading}
            />
          </div>
        }
      />
    </>
  );
};

export default NewPolicy;
